import { computed, ref } from "vue";

export const isLogin = ref(false);
export const signedData = ref("{}");
export const isExclusiveUser = ref(false);
export const hasRegistered = ref({
  main: false,
  handsOnWorkshop: false,
  oneOneMeeting: false,
});
export const checkingUser = ref(false);
export const showVipRegisterModal = ref(false);

export const getLoginData = computed(() => {
  const data = JSON.parse(signedData.value || "{}");
  if (data.name && data.isExclusiveUser && data.name.includes("[VIP]")) {
    data.name = data.name.split("[VIP]")[0].trim();
  }
  return data;
});

export function resetState() {
  isLogin.value = false;
  signedData.value = "{}";
  isExclusiveUser.value = false;
  hasRegistered.value = {
    main: false,
    handsOnWorkshop: false,
    oneOneMeeting: false,
  };
}
