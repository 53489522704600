const PUBLIC_LAYOUT = "default";
const APP_DOMAIN = process.env.VUE_APP_DOMAIN || "localhost";

const PROVINCES = [
  "Hà Nội",
  "Đà Nẵng",
  "Hải Phòng",
  "Hồ Chí Minh",
  "Cần Thơ",
  "Other",
];

const CHECK_USER_TYPE_ENDPOINT =
  "https://us-central1-devfest-200c2.cloudfunctions.net/checkUserType";

const RESOURCE = {
  FORM_REGISTER: "formRegister",
  WORKSHOP: "handsOnWorkshop",
  ONE_ONE_MEETING: "oneOneMeeting",
};

const NAVIGATED_FROM = {
  WORKSHOP: "workshop_register",
  ONE_ONE: "one_one_register",
};

const SPEAKERS = [
  {
    timeSlot: "2:00PM - 2:45PM",
    imageSrc: require("../assets/images/2024/devfest/speakers/lim-shang-yi.png"),
    name: "Shang Yi Lim",
    title: "Google Developer Expert, Firebase",
    color: "pastel-red",
    topic: "Build gen AI features powered by your data with Firebase Genkit",
    category: "#Talk, #Demo, #Intermediate",
  },
  {
    timeSlot: "2:45PM - 3:30PM",
    imageSrc: require("../assets/images/2024/devfest/speakers/su-myat-tun.png"),
    name: "Su Myat Tun",
    title: "Google Developer Expert, Android",
    color: "pastel-yellow",
    topic:
      "From Android to Everywhere: Jetpack Compose in a Multiplatform World",
    category: "#Talk, #Demo, #Intermediate",
  },
  {
    timeSlot: "2:00PM - 3:30PM",
    imageSrc: require("../assets/images/2024/devfest/speakers/linh-nguyen.png"),
    name: "Linh Nguyen",
    title: "Head of AI @ Obello, CTO @ HeyJuni",
    color: "pastel-blue",
    topic:
      "Crafting Resilient AI: A Hands-on Workshop on Attacks, Defenses, and Alignment",
    category: "#Codelab, #Intermediate",
  },
  {
    timeSlot: "3:40PM - 4:25PM",
    imageSrc: require("../assets/images/2024/devfest/speakers/duong-nguyen.png"),
    name: "Duong Nguyen",
    title: "Google Developer Expert, AI/ML",
    color: "pastel-green",
    topic: "Bittensor: A Peer-to-Peer Intelligence Market",
    category: "#Talk, #Demo, #Intermediate",
  },
  {
    timeSlot: "3:40PM - 4:25PM",
    imageSrc: require("../assets/images/2024/devfest/speakers/weiyuan-liu.png"),
    name: "Weiyuan Liu",
    title: "Google Developer Expert, GCP",
    color: "pastel-red",
    topic:
      "Build your own search experience in Google Cloud, with Vertex AI Studio and Vertex AI Agent Builder",
    category: "#Talk, #Demo, #Beginner",
  },
  {
    timeSlot: "4:25PM - 5:10PM",
    imageSrc: require("../assets/images/2024/devfest/speakers/suesi-tran.png"),
    name: "Suesi Tran",
    title: "Google Developer Expert, Dart & Flutter",
    color: "pastel-yellow",
    topic: "Multiple ways to use Gemini in Flutter",
    category: "#Talk, #Intermediate",
  },
  {
    timeSlot: "4:25PM - 5:10PM",
    imageSrc: require("../assets/images/2024/devfest/speakers/thanh-hoang.png"),
    name: "Thanh Hoang",
    title: "CEO @ Patcell",
    color: "pastel-blue",
    topic: "Multiple ways to use Gemini in Flutter",
    category: "#Talk, #Beginner",
  },
  {
    timeSlot: "4:25PM - 5:10PM",
    imageSrc: require("../assets/images/2024/devfest/speakers/dung-tran.png"),
    name: "Dung Tran",
    title: "AI Engineer @ Apero",
    color: "pastel-green",
    topic: "Realtime document scan using AI - A road to success",
    category: "#Talk, #Intermediate",
  },
  {
    timeSlot: "4:25PM - 5:10PM",
    imageSrc: require("../assets/images/2024/devfest/speakers/cuong-nguyen.png"),
    name: "Cuong Nguyen",
    title: "Android Tech Lead @ Apero",
    color: "pastel-red",
    topic: "Realtime document scan using AI - A road to success",
    category: "#Talk, #Intermediate",
  },
  {
    timeSlot: "5:10PM - 5:40PM",
    imageSrc: require("../assets/images/2024/devfest/speakers/tuan-anh-vu.png"),
    name: "Tuan Anh Vu",
    title: "Project Manager @ NashTech",
    color: "pastel-yellow",
    topic:
      "Design Thinking, Lean, Agile, DevOps - To Building Products That Last",
    category: "#Talk, #Intermediate",
  },
];

export {
  APP_DOMAIN,
  CHECK_USER_TYPE_ENDPOINT,
  NAVIGATED_FROM,
  PROVINCES,
  PUBLIC_LAYOUT,
  RESOURCE,
  SPEAKERS,
};
