import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    meta: {
      layout: "none",
    },
    component: () => import("../views/PageNotFound.vue"),
  },
  {
    path: "/",
    name: "new",
    meta: {
      layout: "default",
    },
    component: () => import("../views/NewView.vue"),
  },
  {
    path: "/one-one-meeting",
    name: "OneOneMeeting",
    meta: { layout: "sub" },
    component: () => import("../views/OneOneMeeting.vue"),
  },
  {
    path: "/hands-on-workshop",
    name: "HandOnLab",
    meta: { layout: "sub" },
    component: () => import("../views/HandsOnWorkshop.vue"),
  },
  {
    path: "/my-tickets",
    name: "MyTickets",
    meta: { layout: "sub" },
    component: () => import("../views/MyTickets.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      let top = {};
      switch (to.hash) {
        case "#about":
          top.top = -100;
          break;

        case "#register":
          top.top = 42;
          break;
      }

      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({
            el: to.hash,
            behavior: "smooth",
            ...top,
          });
        }, 200);
      });
    }

    return { top: 0 };
  },
});

export default router;
