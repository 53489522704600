<template>
  <component :is="layout">
    <router-view />
  </component>
</template>

<script>
import Cookies from "js-cookie";
import { computed } from "vue";
import { useRoute } from "vue-router";
import { PUBLIC_LAYOUT } from "./constants/index";
import {
  getLoginData,
  hasRegistered,
  isExclusiveUser,
  isLogin,
  signedData,
} from "./state/global";

export default {
  setup() {
    const route = useRoute();

    signedData.value = Cookies.get("signedData");
    isLogin.value = !!signedData.value;

    if (getLoginData.value.uid) {
      hasRegistered.value.main = getLoginData.value.hasRegistered;
      hasRegistered.value.handsOnWorkshop =
        getLoginData.value.hasRegisteredWorkshop;
      hasRegistered.value.oneOneMeeting =
        getLoginData.value.hasRegisteredOneOne;
      isExclusiveUser.value = getLoginData.value.isExclusiveUser;
    }

    return {
      layout: computed(() => (route.meta.layout || PUBLIC_LAYOUT) + "-layout"),
    };
  },
};
</script>
